import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging) {


    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        console.log("Monem");
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
    // navigator.serviceWorker.addEventListener('message', this.onReciev.bind(this));
  }

  onReciev(event) {
    console.log(event);
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    // this.angularFireMessaging.messaging.subscribe(
    //   (messaging: any) => {
    //     messaging.onMessageCallback = (payload: any) => {
    //       console.log(payload);
    //     };
    //     messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
    //   });

    this.angularFireMessaging.messages.subscribe(
      (message) => {
        console.log('Message received:', message);
      },
      (error) => { console.log("failed to subscribe to firebase messaging") }
    );


    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })

    // this.angularFireMessaging.messaging.subscribe(
    //   (_messaging: any) => {
    //     console.log(_messaging);

    //     // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging._next = (payload: any) => {
    //       console.log(payload);
    //     };
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // );
  }

  receiveMessaging() {
    this.angularFireMessaging.messaging.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        // this.currentMessage.next(payload);
      })
  }
}